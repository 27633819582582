import React, { useState } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Image from 'next/image';
import { signIn, useSession } from 'next-auth/react';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useUser } from '../../hooks';
import { useToast } from '../../hooks/useToast';
import useLocalStorage from '../../hooks/useLocalStorage';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { User } from '../../models';

function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const [, setUser] = useUser();
  const [selectedClientUser, setSelectedClientUser] = useLocalStorage(
    'currentClientUser',
    {} as User
  );
  const [disabled, setDisabled] = useState(false);
  const { data: session } = useSession();
  const currentClientUser = useReadLocalStorage('currentClientUser');
  const { toast } = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSignIn(e);
    }
  };

  const onSignIn = async (e) => {
    e.preventDefault();
    setDisabled(true);
    NProgress.start();
    if (!email || !password) {
      NProgress.done();
      setDisabled(false);
      toast({
        title: 'Error de validación',
        description: 'Por favor, ingresa tu email y contraseña.',
        variant: 'destructive',
      });
      return;
    }

    try {
      const result = await signIn('credentials', {
        email,
        password,
        redirect: false,
      });

      if (result.error) {
        toast({
          title: 'Error al iniciar sesión',
          description: result.error,
          variant: 'destructive',
        });
      } else {
        toast({
          title: 'Inicio de sesión exitoso',
          description: 'Has iniciado sesión correctamente.',
          variant: 'success',
        });
        router.push('/app');
      }
    } catch (err) {
      toast({
        title: 'Error al iniciar sesión',
        description:
          'Ha ocurrido un error inesperado, intenta de nuevo más tarde.',
        variant: 'destructive',
      });
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      <div className=" w-full md:w-1/2 h-full flex place-content-center bg-white">
        <form
          className=" w-fit container gap-4 h-full flex justify-center items-center flex-col"
          onSubmit={onSignIn}
        >
          <h1 className="w-full">
            <span className="text-3xl font-medium text-black">
              Bienvenido de vuelta
            </span>
          </h1>
          <p className="text-gray-500 w-full">
            Por favor ingresa tus datos para continuar
          </p>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Correo
            </label>
            <Input
              type="email"
              id="email"
              placeholder="Ingrese su correo"
              value={email}
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 relative">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Contraseña
            </label>
            <Input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Ingrese su contraseña"
              value={password}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
              onKeyPress={handlePasswordKeyPress}
            />
            <button
              type="button"
              className="absolute right-3 top-8"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <EyeOffIcon className="h-4 w-4" />
              ) : (
                <EyeIcon className="h-4 w-4" />
              )}
            </button>
          </div>
          <div className="w-full flex gap-2">
            <button
              type="button"
              className="text-sm text-primary-600"
              onClick={() => {
                router.push('/reset');
              }}
            >
              Olvidé mi contraseña
            </button>
          </div>
          <Button
            className={`w-full ${disabled ? 'bg-primary-300' : ''}`}
            type="submit"
            disabled={disabled}
          >
            Ingresar
          </Button>
        </form>
      </div>
      <div className="w-1/2 h-full relative hidden md:block ">
        <Image src="/media/img/hero-bg-1.jpg" alt="" fill quality={50} />
      </div>
    </div>
  );
}

export default SignInForm;
